import React, { useState, useEffect } from 'react';
import './Pages.css'
import axios from 'axios';
import Loading from './Loading'

const About = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${apiUrl}/api/users/all`);
                const usersData = response.data;

                setUsers(usersData);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    if (loading) return (<Loading />);
    if (error) return <div>Error: {error}</div>;
    return (
        <div className='about-us page-layout'>
            <h1 className='page-header about-us-header'>Meet the Players</h1>
            <div className='players-container'>
                {users.map((user, index) => (
                    <div className='player-card' key={index}>
                        <img className="player-icon" src={`/assets/${user.userId}.jpeg`}></img>
                        <div className='player-title'>{user.userId}</div>
                        <div className='player-note'>{user.about}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default About;