import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Leaderboard from './Leaderboard';
import Calendar from './Calendar';
import './HomePage.css'
import Loading from './Loading';


const HomePage = () => {
    // const getUserInfo 
    const apiUrl = process.env.REACT_APP_API_URL;
    const [userData, setUserData] = useState(null);
    const [counts, setCounts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false)



    const userId = localStorage.getItem('userId')

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const { data } = await axios.get(`${apiUrl}/api/users/${userId}`);
                setUserData(data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [userId]);

    // Function to fetch log counts for a specific user
    const fetchLogCounts = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/logs/user/${userId}/counts`);
            setCounts(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch logs');
            setLoading(false);
        }
    };



    useEffect(() => {
        fetchLogCounts();
    }, [userId]);

    if (!userData) return (<Loading />)
    return (
        <div className='homepage-page'>
            {/* <div className='header-bar'>
                <button className="log-out-button" onClick={handleLogout}>log out</button>
            </div> */}

            <p className="header-text">hej! how many kilometers did you walk today?</p>

            <div className="homepage-layout">
                <Calendar counts={counts} />
                <Leaderboard />
            </div>

        </div>
    )
}

export default HomePage