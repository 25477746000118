
import React, { useState, useEffect } from 'react';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import Log from './Log';
import './AdventModal.css';


const AdventModal = ({ open, setOpen, selectedDistance, users }) => {
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);
    const [save, setSave] = useState(null)
    const currentUser = localStorage.getItem('userId')
    const you = users.find(user => user.userId === currentUser);
    const youWalked = Boolean(you?.date);

    const wordNums = [
        "Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine",
        "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen",
        "Eighteen", "Nineteen", "Twenty", "Twenty-one", "Twenty-two", "Twenty-three", "Twenty-four"
    ]

    const currDistance = `${wordNums[selectedDistance]} Kilometer${selectedDistance > 1 ? 's' : ''}`

    const handleSave = async () => {
        if (save) {
            const success = await save(); // Call the child's function
            if (success) {
                setOpen(false); // Close the modal only on success
                window.location.reload();
            }
        }
    }

    const getUserProfile = (userId, profilePicture, date) => {
        const youClass = userId === currentUser ? "you" : "";
        const profileClasses = [
            "user-profile",
            youClass,
            youWalked ? "walked" : "do-better"

        ]
        return (
            <div className={profileClasses.join(" ")} key={userId}>
                <div className={`profile-picture ${youClass}`}>
                    <img
                        src={profilePicture}
                        alt={userId}
                        className="object-cover w-full h-full"
                    />
                </div>
                <div className="user-name">
                    {userId}
                </div>
                {
                    <div className="user-date">
                        {formatDecemberDate(date)}
                    </div>
                }

            </div>
        )
    }

    const getLogSection = () => {
        if (youWalked) {
            return (
                <div>You’ve already walked {currDistance.toLowerCase()}. Sleigh.</div>
            )
        }

        return (
            <div>
                Log a new entry! Choose the date that you walked this distance from the picker below.
                <p className='note'>Note: You cannot select a date you have already used before.</p>
                <Log setSave={setSave} distance={selectedDistance} />
            </div>
        )

    }

    const formatDecemberDate = (dateString) => {
        if (!dateString) {
            return ""
        }

        const day = dateString.slice(-2);
        const suffix =
            (day % 10 === 1 && day !== 11) ? "st" :
                (day % 10 === 2 && day !== 12) ? "nd" :
                    (day % 10 === 3 && day !== 13) ? "rd" :
                        "th";

        return `dec ${day}${suffix}`;
    }

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform rounded-lg bg-white text-left shadow-xl transition-all  h-[550px] w-[600px] data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <DialogTitle className="font-semibold text-gray-900 km-title text-center pb-4">
                                        {currDistance}
                                    </DialogTitle>
                                    <div className={"flex-auto overflow-y-auto" + (youWalked ? "walked" : "do-better advent-model-body ")}>
                                        {getLogSection()}
                                        <div className="user-profiles-container">
                                            {
                                                users.sort((a, b) => {
                                                    if (a.userId === currentUser) return -1;
                                                    if (b.userId === currentUser) return 1;
                                                    return a.userId - b.userId;
                                                }).map((user) => (
                                                    getUserProfile(user.userId, user.date ? `/assets/${user.userId}.jpeg` : `/assets/unfinished.jpeg`, user.date)
                                                ))
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="absolute bottom-0 left-0 right-0 flex justify-end space-x-4 px-4 py-3 bg-gray-50 border-t">
                            {!youWalked && <button type="button" className="modal-btn save-button" onClick={handleSave}>Save</button>}
                            <button type="button" className="modal-btn cancel-button" onClick={() => setOpen(false)}>Cancel</button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
};

export default AdventModal;
