import React from 'react';
import './Pages.css'

const Mobile = () => {
    return (
        <div className='mobile-layout'>
            <div>nope 🙅‍♀️</div>
            <div>
                no mobile view. nothing to see here. please use a desktop to view this site
            </div>
        </div>
    );
};

export default Mobile;