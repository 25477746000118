import React from 'react';

const Rules = () => {
    return (
        <div className='rules page-layout'>
            <div className='page-header rules-header'>Rules</div>
            <div className='text-container'>
                <div className='rule-description-top'>the rules are simple (except not really). if you have any questions please reach out to our support team (ash or megan) who will open a ticket with our engineering team (ash or megan) if necessary.
                </div>
                <div className="section-header rules-header">Rule 1</div>
                <div className="rule-description">
                    Between December 1st and December 24th, you must walk between 1 and 24 kilometers every day. You cannot repeat distances but are welcome to switch up the order. So, you can walk 8 kilometers on the first day, 12 on the second, and 3 on the third. Feel free to choose your own schedule.
                </div>

                <div className="section-header rules-header">Rule 2</div>
                <div className="rule-description">
                    You can split your distance for the day across multiple walks if needed. For example, a 4km walk in the morning and an 8km walk at lunch can count as a 12 km day.
                </div>

                <div className="section-header rules-header">Rule 3</div>
                <div className="rule-description">
                    You are able to go back and log walks on past dates if you forgot to, but once you have logged a walk for a distance, that cannot be undone.
                    <br />
                    <span className='note'>Within reason, of course, we do understand if you accidentally clicked a button. However, there is no way to undo this in the UI, so if this happens, please contact our support team (Ash or Megan), and they will get this remedied ASAP.</span>
                </div>

                <div className="section-header rules-header">Rule 4</div>
                <div className="rule-description">
                    This season of 24ks is built on the honor system because we did not have enough time to implement photo upload. We trust that you will log days that you actually completed.
                </div>

                <div className="section-header rules-header">Rule 5</div>
                <div className="rule-description">
                    You must do each distance on an individual day. You cannot double up 1 and 2 km as two different walks on one day and then count that for two separate dates. Sorry. Move your butt and walk 2 km again another day.
                </div>
            </div>
            <div className='page-header rules-header'>Tips</div>
            <div className='text-container'>

                <div className="section-header rules-header">Tip 1</div>
                <div className="rule-description">
                    Apple Watch users, you can change your units of measure to be in kilometers for the month of December so it’s easier to track your progress.
                    <br />
                    To do so, go to the fitness app on your phone and click your profile in the top right. Navigate to <span className='emphasis'>Units of Measure</span> and change walking and running workouts to kilometers
                </div>
                <div className="section-header rules-header">Tip 2</div>
                <div className="rule-description">lock in</div>
            </div>
        </div>
    );
};

export default Rules;