import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Login from './Login'; // Import your Login component
import HomePage from './HomePage'; // Your authenticated main page (e.g., leaderboard)
import './App.css';
import About from './About'
import Careers from './Careers';
import Rules from './Rules';
import Footer from './Footer';
import Header from './Header';
import Mobile from './Mobile';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check if the user is logged in on component mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    document.title = '24ks of Christmas!'
    if (token) {
      setIsAuthenticated(true); // User is logged in if token exists
    }
  }, []);

  function isMobileDevice() {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  const isMobile = isMobileDevice();
  if (isMobile) {
    return <Mobile />;
  }
  return (
    <Router>
      {isAuthenticated && <Header />}
      <div>
        {/* Routes */}
        <Routes>
          {/* Always redirect unauthenticated users to the login page */}
          {!isAuthenticated ? (
            <Route path="*" element={<Login />} />
          ) : (
            <>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<About />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/rules" element={<Rules />} />
            </>
          )}
        </Routes>
      </div>
      {isAuthenticated && <Footer />}
    </Router>
  );
};

export default App;
