import React, { useState } from 'react';
import axios from 'axios';
import './Login.css'
import { useNavigate } from 'react-router-dom';
import Loading from './Loading'

const Login = () => {
    const [userId, setUserId] = useState('');
    const [keyword, setKeyword] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()

    const handleLogin = async () => {
        try {
            setLoading(true);
            const apiUrl = process.env.REACT_APP_API_URL;
            const { data } = await axios.post(`${apiUrl}/api/auth/login`, { userId, keyword });
            localStorage.setItem('token', data.token);
            localStorage.setItem('userId', userId)

            // Automatically refresh the page after successful login
            window.location.reload();
            setLoading(false);
            navigate("/")
        } catch (err) {
            console.log(err)
            alert('Login failed!');
            setLoading(false);
        }
    };

    if (loading) return (<Loading />);
    return (
        <div className='login-page'>
            <div className="login-fields">
                <p className="header-text">new phone who dis</p>
                <div><input className="input-field" placeholder="your goverment name pls" value={userId} onChange={(e) => setUserId(e.target.value)} /></div>
                <div ><input className="input-field" placeholder="super secret password" value={keyword} onChange={(e) => setKeyword(e.target.value)} /></div>
                <button className="log-in-button" onClick={handleLogin}>let me in</button>
            </div>
        </div>
    );
};

export default Login;