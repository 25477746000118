import React from 'react';
import { Link } from 'react-router-dom';

const Careers = () => {
    return (
        <div className='page-layout'>
            <div className='page-header careers-header'>Join Our Team!</div>
            <div className='careers-header-card'>
                <div>
                    Are you a passionate innovator with a growth mindset? Do you thrive in a fast-paced environment where collaboration, cutting-edge technology, and impact-driven results fuel success? We're looking for dedicated professionals who are eager to contribute to transformative business outcomes and scale with purpose. 🚀
                </div>
                <hr className='divider' />
                <div className='sub-header'>Open Roles</div>
                <div>
                    <div className='job-title-container'>
                        <div className='job-title-header'>Viscount of Sales</div>
                        <Link to="https://forms.gle/Mvnt2TYXyScatws49" target="_blank"><div className='job-link'>Apply ↗</div></Link>
                    </div>
                    <div className='job-description'>we're looking for a sales person to head our sales department so we can do the sales and be rich</div>
                    <div className='job-details-container'>
                        <span className='job-detail-salary'>80k-120k</span><span className='job-detail'>📍 Copenhagen</span>
                    </div>
                </div>
                <hr className='divider' />

                <div>
                    <div className='job-title-container'>
                        <div className='job-title-header'>Executive Assistant to Ash</div>
                        <Link to="https://forms.gle/CxkveppSor5fmfu7A" target="_blank"><div className='job-link'>Apply ↗</div></Link>
                    </div>
                    <div className='job-description'>do literally everything for ash, be at her constant beck and call. always have pickle in hand. will need to park in capitol hill</div>
                    <div className='job-details-container'>
                        <span className='job-detail-salary'>$1,000,000 base</span><span className='job-detail'>📍 Seattle</span>
                    </div>
                </div>
                <hr className='divider' />


                <div>
                    <div className='job-title-container'>
                        <div className='job-title-header'>Emperor of Human Resources</div>
                        <Link to="https://forms.gle/5kpjDtwmECSLw7hx5" target="_blank"><div className='job-link'>Apply ↗</div></Link>
                    </div>
                    <div className='job-description'>fix everything</div>
                    <div className='job-details-container'>
                        <span className='job-detail-salary'>80k-120k</span><span className='job-detail'>📍 Remote</span>
                    </div>
                </div>



            </div>
        </div>
    );
};

export default Careers;