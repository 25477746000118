import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Log.css'

const Log = ({ distance, setSave }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [error, showError] = useState(false)
    const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    };
    const [date, setDate] = useState(formatDate(new Date()))
    const userId = localStorage.getItem('userId')
    const [idolSelected, setIdolSelected] = useState(false)
    const [userData, setUserData] = useState(null)

    useEffect(() => {
        if (setSave) {
            setSave(() => logWalk);
        }
    }, [setSave, date, idolSelected]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const { data } = await axios.get(`${apiUrl}/api/users/${userId}`);
                setUserData(data);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [userId]);

    const logWalk = async () => {
        try {
            const { data } = await axios.post(`${apiUrl}/api/log/logWalk`, { userId, distance, date, idolSelected });
            return true;
        } catch (err) {
            showError(true)
            return false;
        }
    };


    const handleIdolClick = () => {
        setIdolSelected(!idolSelected)
    }

    return (
        <div className='log-container'>
            <input className='date-picker' type="date" value={date} onChange={(e) => { setDate(e.target.value) }} min="2024-12-01"
                max="2024-12-24" />
            {error && <div className='error'>
                <span>
                    <img className='img-icon' src={`/attention-circle.svg`} />
                </span>
                <span>Oops, you’ve logged an entry for this date already. Be better.</span>
            </div>
            }
            {userData?.hasImmunityIdol && distance <= 18 && <div>
                <div className='idol-text'>You are in possession of a hidden immunity idol! You can only use this once! Check if you wish to play the idol on this distance and skip the selected day. </div>
                <div className='idol-container'>
                    <div onClick={handleIdolClick} className={`checkbox-idol  ${idolSelected ? 'selected ' : ''}`}>{idolSelected && 'X'}</div>
                    <span className='play-the-idol'>Play your Idol</span>
                </div>
            </div>
            }
        </div>
    );
};

export default Log;