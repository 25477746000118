import React from "react";
import { Link, useNavigate } from "react-router-dom";
import './Header.css'

const Header = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('userId')

        // Automatically refresh the page after successful logout
        window.location.reload();
    }

    return (
        <header className="flex justify-between items-center" style={{ 'position': 'fixed' }}>
            {/* Logo Section */}
            <Link to="/">
                <img className="logo" src="./logo-nobg.png">
                </img>
            </Link>

            <button className="log-out-button" onClick={handleLogout}>log out</button>
        </header>
    );
};

export default Header;