import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Leaderboard.css'
import Loading from './Loading';

const Leaderboard = () => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${apiUrl}/api/users/all`);
                const usersData = response.data;

                // sort users by totalKilometers in descending order
                usersData.sort((a, b) => b.totalKilometers - a.totalKilometers);

                setUsers(usersData);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    if (loading) return (<Loading />)
    if (error) return <div>Error: {error}</div>;

    return (
        <div className='leaderboard'>
            <div className='leaderboard-header'>Progress</div>
            <table>
                <tbody>
                    {users.map((user, index) => (
                        <div className='leaderboard-item'>
                            <img 
                                className="leaderboard-icon" 
                                src={`/assets/${user.userId}.jpeg`}
                                style={{
                                    opacity: user.numlogs >= new Date().getDate() - 4 ? '100%' : '30%'
                                }}
                            
                            ></img>
                            <span
                                style={{
                                    color: user.numlogs >= new Date().getDate() - 4 ? 'black' : '#808080',
                                }}
                            >
                                {user.userId}
                                </span>
                            <div className="progress-bar">
                                <div
                                    className="progress-fill"
                                    style={{
                                        width: `${(user.totalKilometers / 300) * 100}%`,
                                        color: user.totalKilometers < 60 ? '#203D1B' : 'white',
                                        backgroundColor: user.numlogs >= new Date().getDate() - 4 ? '#203D1B' : '#808080'
                                    }}
                                >
                                    <span style={{
                                        marginLeft: user.totalKilometers < 60 ? `${(user.totalKilometers / 300) * 100 + 20}px` : '0px',// Change text color based on width
                                    }} className="progress-text">{user.totalKilometers}k</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Leaderboard;